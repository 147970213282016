/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setIsAudioConnected } from '../reducers/rhinocallReducer';

import { registerAudioStreamChanged, unregisterAudioStreamChanged } from '../services/RhinocallService';
import { generateUUID } from '../helpers/DataHelpers';

const RhinocallStream = (props) => {
  const audioRef = useRef(null);
  const dispatch = useDispatch();
  const [remoteStream, setRemoteStream] = useState(null);
  const {
    selectedAudioOutputDeviceId,
    isWebRTCInitiated,
  } = props;

  const consumerId = generateUUID();

  useEffect(() => {
    if (isWebRTCInitiated) {
      registerAudioStreamChanged(consumerId, setRemoteStream);
    }
    return () => {
      unregisterAudioStreamChanged(consumerId);
    };
  }, [isWebRTCInitiated]);

  useEffect(() => {
    if (remoteStream && audioRef?.current?.srcObject !== remoteStream) {
      initiateAudioElement(remoteStream);
      dispatch(setIsAudioConnected(true));
    }
  }, [remoteStream]);

  async function initiateAudioElement(stream) {
    audioRef.current.srcObject = stream;
    if (selectedAudioOutputDeviceId && selectedAudioOutputDeviceId !== 'default') {
      try {
        await audioRef.current.setSinkId(selectedAudioOutputDeviceId);
      } catch {
        // if error, default output is used
      }
    }
  }

  return (
    <>
      {remoteStream && (
        <audio
          playsInline
          autoPlay
          ref={audioRef}
          className="u-opacity-none"
        />
      )}
    </>

  );
};

RhinocallStream.propTypes = {
  selectedAudioOutputDeviceId: PropTypes.string,
  isWebRTCInitiated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  selectedAudioOutputDeviceId: state.rhinocall.selectedAudioOutputDeviceId,
  isWebRTCInitiated: state.rhinocall.isWebRTCInitiated,
});

export default connect(mapStateToProps)(RhinocallStream);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { UtilitySystem } from 'rhinostyle';
import CopyToContent from '../components/CopyToContent';
import * as UserReducer from '../reducers/userReducer';
import { fetchEventsForSavedContentItem, createCopySavedContent } from '../reducers/savedContentReducer';
import { MaskingHelpers } from '../helpers';
import { getCurrentOrg } from '../selectors/organizationSelectors';
import { isSpecifiedIntegrationType } from '../helpers/OrganizationHelpers';
import { TYPE_INTEGRATION_EPIC, TYPE_USER_OTHER } from '../constants/Types';
import { ValidationService, ValidationShapers } from '../services/ValidationService';

class CopyToContentModalContainer extends Component {
  state = {
    selectedContactId: null,
    contactSearchValue: '',
    pageNumber: 0,
    isCopyNowButtonLoading: false,
    usersLoading: false,
    activeFilterParam: 'name',
    inputFormat: null,
    savedContentOfficeId: -1,
    selectedPatientIdForSendingContent: null,
    copyToContentErrors: {},
  }

  componentDidMount() {
    this.searchHandler = debounce(this.searchHandler, 300);
    this.setInputFormat();
  }

  selectContactHandler = (firstName, lastName, contactId) => {
    this.setState({
      selectedContactId: contactId,
    });
  }

  handleContactSelect = (contactId) => {
    this.setState({
      selectedPatientIdForSendingContent: contactId,
    });
  };

  setInputFormat = () => {
    const { activeFilterParam } = this.state;

    this.setState({
      inputFormat: null,
    }, () => {
      let inputFormat = null;

      if (activeFilterParam === 'dob') {
        inputFormat = UtilitySystem.dateFormat;
      } else if (activeFilterParam === 'phone') {
        inputFormat = MaskingHelpers.phone;
      }

      this.setState({
        inputFormat,
      });
    });
  }

  defaultState = () => {
    this.setState({
      selectedContactId: null,
      selectedPatientIdForSendingContent: null,
      savedContentOfficeId: -1,
    });
  }

  closeModal = () => {
    this.props.clearUserSearch();
    this.props.toggleEHROptionHandler(false);
    this.props.handleToggleCopyToModal(false);
    this.setState({
      selectedContactId: null,
      selectedPatientIdForSendingContent: null,
      savedContentOfficeId: -1,
      contactSearchValue: '',
      isCopyNowButtonLoading: false,
      activeFilterParam: 'name',
    }, () => this.setInputFormat());
  };

  handleOfficeChange = (name, value) => {
    this.setState({
      savedContentOfficeId: value,
    });
  };

  handleSave = () => {
    const isSendingToEHR = this.props.isEHROptionVisible && this.props.isEHROptionChecked;
    const isCopyToContact = this.state.selectedContactId;
    let isGoodEHRSend = !isSendingToEHR;
    let isGoodCopyContent = !isCopyToContact;
    const { activeUser } = this.props;
    const isActiveUserOtherType = activeUser.typeId === TYPE_USER_OTHER;
    if (isSendingToEHR || isCopyToContact) {
      const isEpicIntegration = isSpecifiedIntegrationType(this.props.currentOrganization, TYPE_INTEGRATION_EPIC);
      this.setState({
        isCopyNowButtonLoading: true,
      }, async () => {
        if (isSendingToEHR) {
          const payload = {};
          if (isEpicIntegration) {
            const {
              savedContentOfficeId,
              selectedPatientIdForSendingContent,
            } = this.state;
            const { encounterDate } = this.props.activeSavedContentItem;
            payload.savedContentOfficeId = savedContentOfficeId;
            payload.selectedPatientIdForSendingContent = selectedPatientIdForSendingContent;
            payload.encounterDate = encounterDate;
            const errors = ValidationService(ValidationShapers.shapeCopyToContent({
              savedContentOfficeId,
              isSendingToEHR,
              isEpicIntegration,
              isActiveUserOtherType,
            }));
            const errorCount = Object.keys(errors).length;
            if (errorCount > 0) {
              this.setState({ copyToContentErrors: errors, isCopyNowButtonLoading: false });
              isGoodEHRSend = false;
            } else {
              await this.props.sendToEHRHandler(payload);
              this.setState({ copyToContentErrors: {} });
              isGoodEHRSend = true;
            }
          } else {
            await this.props.sendToEHRHandler({});
            isGoodEHRSend = true;
          }
        }
        if (isCopyToContact && isGoodEHRSend) {
          await this.props.createCopySavedContent({
            savedContentEventId: this.props.savedContentId,
            userId: activeUser.id,
            contactId: this.state.selectedContactId,
          });
          isGoodCopyContent = true;
        }
        if (isGoodCopyContent && isGoodEHRSend) {
          this.setState({ isCopyNowButtonLoading: false });
          this.closeModal();
          this.props.fetchEventsForSavedContentItem(parseInt(this.props.savedContentId, 10), this.state.pageNumber);
        }
      });
    }
  };

  handleFilterChange = (filterBy) => {
    this.setState({
      activeFilterParam: filterBy,
      contactSearchValue: '',
      selectedContactId: null,
    }, () => {
      this.setInputFormat();
    });
  }

  searchHandler = (id, searchValue, searchValueFormatted) => {
    const { activeFilterParam } = this.state;
    const searchText = (['dob', 'phone'].includes(activeFilterParam)) ? searchValueFormatted : searchValue;
    this.setState({
      contactSearchValue: searchText,
      usersLoading: true,
      selectedContactId: null,
    }, async () => {
      if (this.state.contactSearchValue.length > 2) {
        await this.props.fetchUserSearch({
          searchValue: this.state.contactSearchValue,
          scope: 'nonMembers',
          type: this.state.activeFilterParam,
          source: 'globalSearch',
          idsToExclude: [this.props.activeUser.id],
        });
      } else {
        this.props.clearUserSearch();
      }
      this.setState({ usersLoading: false });
    });
  }

  onScroll = () => {
    const scrollContainer = this.copyToContentContactListRef.container.firstChild;
    const totalScroll = scrollContainer.scrollTop + scrollContainer.clientHeight;
    const { fetchUserSearch, searchSize, page } = this.props;
    const { activeFilterParam } = this.state;
    const searchText = this.state.contactSearchValue;
    const source = 'globalSearch';
    const scope = 'nonMembers';

    if (totalScroll === scrollContainer.scrollHeight) {
      this.scrollPosition = 'bottom';

      fetchUserSearch({
        searchValue: searchText,
        scope,
        type: activeFilterParam,
        source,
        page: page + searchSize,
        idsToExclude: [this.props.activeUser.id],
      });
    }

    if ((totalScroll !== scrollContainer.scrollHeight) && (scrollContainer.scrollTop !== 0)) {
      this.scrollPosition = 'middle';
    }

    if (scrollContainer.scrollTop === 0) {
      this.scrollPosition = 'top';
    }
  }

  render() {
    const props = {
      isCopyToContentModalOpen: this.props.isCopyToContentModalOpen,
      closeModal: this.closeModal,
      connectedParties: this.props.connectedParties,
      types: this.props.types,
      selectedContactId: this.state.selectedContactId,
      activeUser: this.props.activeUser,
      isEHROptionVisible: this.props.isEHROptionVisible,
      isEHROptionChecked: this.props.isEHROptionChecked,
      toggleEHROptionHandler: this.props.toggleEHROptionHandler,
      contactSearchValue: this.state.contactSearchValue,
      userSearchIds: this.props.userSearchIds,
      users: this.props.users,
      activeSavedContentItem: this.props.activeSavedContentItem,
      isCopyNowButtonLoading: this.state.isCopyNowButtonLoading,
      usersLoading: this.state.usersLoading,
      activeFilterParam: this.state.activeFilterParam,
      inputFormat: this.state.inputFormat,
      handleSave: this.handleSave,
      selectContactHandler: this.selectContactHandler,
      searchHandler: this.searchHandler,
      handleFilterChange: this.handleFilterChange,
      copyToContentContactListRef: (copyToContentContactListRef) => (this.copyToContentContactListRef = copyToContentContactListRef),
      onScroll: this.onScroll,
      userSearchLoading: this.props.userSearchLoading,
      currentOrganization: this.props.currentOrganization,
      handleOfficeChange: this.handleOfficeChange,
      officesList: this.props.officesList,
      savedContentOfficeId: this.state.savedContentOfficeId,
      handleContactSelect: this.handleContactSelect,
      copyToContentErrors: this.state.copyToContentErrors,
    };

    return <CopyToContent {...props} />;
  }
}

const mapStateToProps = (state) => {
  const { ui, type, user, office } = state;
  const { offices, officeIds } = office;
  const currentOrganization = getCurrentOrg(state);
  const officesList = officeIds?.map((o) => ({ id: offices[o]?.id, value: offices[o]?.name }));
  officesList?.unshift({ id: -1, value: '--' });

  return {
    error: ui.error,
    types: type.types,
    userSearchIds: user.userSearchIds,
    users: user.users,
    page: user.page,
    searchSize: user.searchSize,
    userSearchLoading: user.userSearchLoading,
    currentOrganization,
    officesList,
  };
};

const actions = {
  createCopySavedContent,
  clearUserSearch: UserReducer.clearUserSearch,
  fetchUserSearch: UserReducer.fetchUserSearch,
  fetchEventsForSavedContentItem,
};

CopyToContentModalContainer.propTypes = {
  isCopyToContentModalOpen: PropTypes.bool.isRequired,
  handleToggleCopyToModal: PropTypes.func.isRequired,
  connectedParties: PropTypes.array.isRequired,
  types: PropTypes.object.isRequired,
  activeSavedContentItem: PropTypes.object.isRequired,
  activeUser: PropTypes.object.isRequired,
  isEHROptionVisible: PropTypes.bool.isRequired,
  isEHROptionChecked: PropTypes.bool.isRequired,
  toggleEHROptionHandler: PropTypes.func.isRequired,
  sendToEHRHandler: PropTypes.func.isRequired,
  savedContentId: PropTypes.number.isRequired,
  userSearchLoading: PropTypes.bool.isRequired,
  createCopySavedContent: PropTypes.func,
  users: PropTypes.object,
  userSearchIds: PropTypes.array,
  fetchUserSearch: PropTypes.func,
  clearUserSearch: PropTypes.func,
  fetchEventsForSavedContentItem: PropTypes.func,
  searchSize: PropTypes.number,
  page: PropTypes.number,
  currentOrganization: PropTypes.object,
  handleOfficeChange: PropTypes.func,
  officesList: PropTypes.array,
  savedContentOfficeId: PropTypes.string,
  handleContactSelect: PropTypes.func,
  copyToContentErrors: PropTypes.object,
};

export default connect(mapStateToProps, actions)(CopyToContentModalContainer);

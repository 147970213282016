import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Close,
  Collapse,
  Icon,
  Modal,
  ModalFooter,
  ModalHeader,
  Scrollbars,
  UtilityInlineGrid,
} from 'rhinostyle';
import { useHistory, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PdfViewerContainer from '../containers/PdfViewerContainer';
import CopyToContentModalContainer from '../containers/CopyToContentModalContainer';
import { DateHelpers, UserHelpers, OrganizationHelpers } from '../helpers';
import { handleDownloadClick } from '../helpers/PdfViewerHelpers';
import { getAttachmentUrl } from '../helpers/SavedContentHelpers';
import { CONTENT_SELECT_AND_SAVE_DELETE, CONTACT_EDIT } from '../constants/UserPermissionsConstants';
import { AppConstants } from '../constants';
import {
  TYPE_USER_LIBRARY_FORM,
} from '../constants/Types';
import {
  PANEL_OPTIONS,
} from '../constants/ThreadConstants';
import { getLoggedInUser, userHasLimitedProviderRole } from '../selectors/userSelectors';
import { fetchEventsForSavedContentItem, destroySavedContentItem, sendSavedContentItem } from '../reducers/savedContentReducer';
import { fetchUser } from '../reducers/userReducer';
import { handleCloseSavedContentPanel } from '../reducers/threadReducer';
import { getSavedContentCreator, getSavedContentContact, getSavedContentItem, getSavedContentConnectedParties } from '../selectors/savedContentSelectors';
import { getCurrentOrg } from '../selectors/organizationSelectors';

const SavedContentPanel = (props) => {
  const {
    allowMobileView,
    handleMobileViewSavedContent,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { canGoBack } = history;
  const canClosePreviewView = canGoBack && params?.savedContentItemId;
  const { COPY_DOCUMENT_DEFAULT_LIMIT } = AppConstants;
  const isContactEditPermissionPresent = UserHelpers.userHasAnyOfPermissions([CONTACT_EDIT]);
  const currentUser = useSelector((state) => getLoggedInUser(state));
  const isNativeApp = useSelector((state) => state.nativeApp.isNativeApp);
  const activeSavedContentItem = useSelector((state) => getSavedContentItem(state, params));
  const activeUser = useSelector((state) => getSavedContentContact(state, params));
  const [activeUserId, setActiveUserId] = useState(null);
  const savedContentCreatedByUser = useSelector((state) => getSavedContentCreator(state, params));
  const activeUserConnectedParties = useSelector((state) => getSavedContentConnectedParties(state, params));
  const currentOrg = useSelector((state) => getCurrentOrg(state));
  const isLimitedProvider = useSelector((state) => userHasLimitedProviderRole(state));
  const isForm = activeSavedContentItem?.libraryTypeId === TYPE_USER_LIBRARY_FORM;
  const areActivitiesPresent = activeSavedContentItem?.activities?.length > 0;
  const areSendActivitiesPresent = activeSavedContentItem?.sendContentActivities?.length > 0;
  const isCopiedContent = areActivitiesPresent && activeSavedContentItem.activities.some((activity) => activity.from === 1);
  const isLastEHRActivityPresent = !!(activeSavedContentItem.lastEHRActivity?.sender);
  const isNoteLengthAboveTruncateLength = activeSavedContentItem?.note?.length > AppConstants.NOTE_TRUNCATE_LENGTH;
  const [isDeleteModalOpen, handleToggleDeleteModal] = useState(false);
  const [defaultViewSavedContent, setDefaultViewSavedContent] = useState(COPY_DOCUMENT_DEFAULT_LIMIT - isLastEHRActivityPresent);
  const [isCopyToContentModalOpen, handleToggleCopyToModal] = useState(false);
  const [isPdfViewerOpen, togglePdfViewer] = useState(false);
  const [isDetailsSectionOpen, handleToggleDetailsSection] = useState(true);
  const [sendContent, handleToggleSendContent] = useState(false);
  const [copyLinkText, setCopyLinkText] = useState(AppConstants.defaultCopyLinkText);
  const [isNoteTruncated, setIsNoteTruncated] = useState(isNoteLengthAboveTruncateLength);
  const [isSavedContentDeleteInProgress, setIsSavedContentDeleteInProgress] = useState(false);
  const allowToClose = canClosePreviewView || !params?.savedContentItemId;

  useEffect(() => {
    if (activeSavedContentItem.id) {
      if (isForm) {
        dispatch(fetchEventsForSavedContentItem(activeSavedContentItem.id, 0));
      }
      setActiveUserId(activeSavedContentItem.contact);
    } else {
      handleClosePanel();
    }
  }, [activeSavedContentItem.id]);

  useEffect(() => {
    setIsNoteTruncated(isNoteLengthAboveTruncateLength);
  }, [isNoteLengthAboveTruncateLength]);

  const contentType = isForm ? 'Form' : 'Saved Content';
  const originalForm = isForm &&
    activeUser?.forms?.find((form) => form.formInternalData === activeSavedContentItem.savedContentPdfLink);
  const linkToPdf = `${process.env.REACT_APP_URL}/auth?path=/saved-content/${activeSavedContentItem.id}?isDirectLink=true`;
  const toggleNoteTruncate = () => setIsNoteTruncated(!isNoteTruncated);
  const getNote = () => {
    const { note } = activeSavedContentItem;

    let html = note.replace(/(\n|\r)+/g, '<br />');
    if (isNoteTruncated) {
      const viewMoreText = '...View More';
      html = `${html.substr(0, AppConstants.NOTE_TRUNCATE_LENGTH - viewMoreText.length)}... `;
    } else {
      html = `${html} `;
    }

    return html;
  };

  const sendSavedContent = async (payload) => {
    await handleSendSavedContent(payload, activeSavedContentItem.id);
    handleToggleSendContent(!sendContent);
  };

  const handleUserTransition = async (userId) => {
    if (!isLimitedProvider) {
      await dispatch(fetchUser(userId));
      history.push({ pathname: `/inbox/all/user/${userId}`, search: `?activePanel=${PANEL_OPTIONS.library}`, ...isForm && { state: { activeTab: 'forms' } } });
    }
  };

  function downloadPDF(fileName, contactId) {
    handleDownloadClick(getAttachmentUrl(fileName, contactId), fileName, isNativeApp);
  }

  const renderTimeStamp = () => {
    if (originalForm) {
      return `Completed on ${DateHelpers.formatTimestamp(activeSavedContentItem.createdAt, 'ddd, MMM D, Y, h:mm a', true)} by ${UserHelpers.formatName(activeUser)}`;
    } return `${isCopiedContent ? 'Copied' : 'Saved'} on ${UserHelpers.formatName(activeUser)}'s profile
      ${DateHelpers.formatTimestamp(activeSavedContentItem.createdAt, 'ddd, MMM D, Y, h:mm a', true)}
      by ${UserHelpers.formatName(savedContentCreatedByUser)}`;
  };

  function handleClosePanel() {
    if (params?.savedContentItemId) {
      if (canGoBack) {
        history.goBack();
      } else {
        history.push(`/inbox/all/user/${activeUserId}`);
      }
    } else {
      dispatch(handleCloseSavedContentPanel(isForm ? 'forms' : null));
    }
  }

  async function handleDeleteSavedContent() {
    setIsSavedContentDeleteInProgress(true);
    await dispatch(destroySavedContentItem(activeSavedContentItem.id, isCopiedContent));
    setIsSavedContentDeleteInProgress(false);
  }

  async function handleSendSavedContent(payload) {
    await dispatch(sendSavedContentItem(payload, activeSavedContentItem.id));
  }

  if (!activeSavedContentItem?.id) { return null; }

  return (
    <>
      <div className="summary-panel__wrapper">
        <div className="summary-panel">
          <div className="app-page__header">
            <div className="app-page__header__title">{contentType} Actions</div>
            {allowToClose && (
              <div className="app-page__header__action">
                <Close
                  className="app-page__header__action__close"
                  onClick={handleClosePanel}
                />
              </div>
            )}
          </div>
          {allowMobileView && (
            <div className="saved-content__view-content--mobile u-flex-justify-between">
              <Button type="link" onClick={handleMobileViewSavedContent}>View {contentType}</Button>
            </div>
          )}
          <Scrollbars className="summary-panel__body u-flex-justify-between">
            <div className="summary-panel__content">
              <div className="summary-panel__section summary-panel__section--full saved-content__summary-panel__details-section">
                <div className="summary-panel__section__label u-m-b-small">
                  Details
                  <div className="summary-panel__section__label__action">
                    <Icon
                      icon={isDetailsSectionOpen ? 'caret-up' : 'caret-down'}
                      className="u-text-muted"
                      onClick={() => handleToggleDetailsSection(!isDetailsSectionOpen)}
                    />
                  </div>
                </div>
                <Collapse isOpen={isDetailsSectionOpen}>
                  <div className="summary-panel__section__content">
                    <div className="saved-content__panel__title">{activeSavedContentItem.title}</div>
                    {activeSavedContentItem.note && (
                      <div className="saved-content__panel__note">
                        {isNoteLengthAboveTruncateLength ? (
                          <>
                            {ReactHtmlParser(getNote())}
                            <Button className="u-text-primary" reset onClick={toggleNoteTruncate}>
                              View {isNoteTruncated ? 'More' : 'Less'}
                            </Button>
                          </>
                        ) :
                          ReactHtmlParser(getNote())}
                      </div>
                    )}
                    <div className="saved-content__panel__file">
                      <div className="saved-content__panel__file__icon">
                        <span className="u-text-primary">PDF</span>
                      </div>
                      <span
                        className="saved-content__panel__file__name"
                        onClick={() => togglePdfViewer(true)}
                      >
                        {activeSavedContentItem.savedContentPdfLink}
                      </span>
                      <Icon
                        className="saved-content__panel__file__download-icon"
                        icon="download"
                        onClick={() => downloadPDF(activeSavedContentItem.savedContentPdfLink, activeSavedContentItem.contact)}
                      />
                    </div>
                    <div className="saved-content__panel__audit-information">
                      {renderTimeStamp()}
                    </div>
                    {!isForm && (
                      <div className="saved-content__panel__link__wrapper">
                        <div className="saved-content__panel__link__text">{linkToPdf}</div>
                        <CopyToClipboard
                          text={linkToPdf}
                          onCopy={() => {
                            setCopyLinkText(AppConstants.clickedCopyLinkText);
                            setTimeout(() => setCopyLinkText(AppConstants.defaultCopyLinkText), 2500);
                          }}
                        >
                          <Button reset type="link" size="small" className="saved-content__panel__link__copy-button">{copyLinkText}</Button>
                        </CopyToClipboard>
                      </div>
                    )}
                  </div>
                </Collapse>
              </div>
              {areSendActivitiesPresent && (
                <div>
                  <div className="sepration" />
                  <div className="summary-panel__section__label u-m-b-small u-m-t">SENT DOCUMENT</div>
                  {activeSavedContentItem.sendContentActivities?.map((activity, index) => {
                    const user = activity.contact;
                    const actionByUser = activity.createdByUser;
                    const isFromLableVisible = activity.from === 1;
                    const actionDate = DateHelpers.formatTimestamp(activity.createdAt, 'ddd, MMM D, Y, h:mm a', true);
                    if (index + 1 > defaultViewSavedContent) return null;
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={index} className="u-m-b">
                        {isFromLableVisible ? (
                          <div className="u-font-weight-bold">Copied to EHR/PMS from &nbsp;
                            <Button className="u-text-primary" reset onClick={() => handleUserTransition(activity.contactId)}>
                              {user.firstName} {user.lastName}
                            </Button>
                          </div>
                        ) : (
                          <div className="u-font-weight-bold">Copied to &nbsp;
                            {activeSavedContentItem?.contactId !== activity?.contactId && (
                              <Button className="u-text-primary" reset onClick={() => handleUserTransition(activity.contactId)}>
                                {user.firstName} {user.lastName} &nbsp;
                              </Button>
                            )}
                            EHR/PMS
                          </div>
                        )}
                        <div className="saved-content__panel__audit-information">
                          {actionDate} by {actionByUser.firstName} {actionByUser.lastName}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {defaultViewSavedContent !== activeSavedContentItem.sendContentActivities?.length &&
                activeSavedContentItem.sendContentActivities?.length > (COPY_DOCUMENT_DEFAULT_LIMIT - 1) && (
                  <div className="u-text-left">
                    <Button onClick={() => setDefaultViewSavedContent(activeSavedContentItem.sendContentActivities?.length)} size="small" type="link">View all</Button>
                  </div>
              )}
              {(areActivitiesPresent) && (
                <div>
                  <div className="sepration" />
                  <div className="summary-panel__section__label u-m-b-small u-m-t">COPY DOCUMENT</div>
                  {activeSavedContentItem.activities?.map((activity, index) => {
                    const user = activity.contact;
                    const actionByUser = activity.createdByUser;
                    const isFromLableVisible = activity.from === 1;
                    const actionDate = DateHelpers.formatTimestamp(activity.createdAt, 'ddd, MMM D, Y, h:mm a', true);
                    if (index + 1 > defaultViewSavedContent) return null;
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={index} className="u-m-b">
                        <div className="u-font-weight-bold">Copied {isFromLableVisible ? 'from' : 'to'}&nbsp;
                          <Button className="u-text-primary" reset onClick={() => handleUserTransition(activity.contactId)}>
                            {user.firstName} {user.lastName}
                          </Button> Library
                        </div>
                        <div className="saved-content__panel__audit-information">
                          {actionDate} by {actionByUser.firstName} {actionByUser.lastName}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {defaultViewSavedContent !== activeSavedContentItem.activities?.length &&
                activeSavedContentItem.activities?.length > (COPY_DOCUMENT_DEFAULT_LIMIT - 1) && (
                  <div className="u-text-left">
                    <Button onClick={() => setDefaultViewSavedContent(activeSavedContentItem.activities?.length)} size="small" type="link">View all</Button>
                  </div>
              )}
              {isContactEditPermissionPresent && !currentUser.isCcr && (
                <div className="u-text-right">
                  <Button
                    type="secondary"
                    onClick={() => handleToggleCopyToModal(true)}
                  >
                    <Icon
                      className="u-m-r-small"
                      icon="share"
                    />
                    Copy To
                  </Button>
                </div>
              )}
              {UserHelpers.userHasAnyOfPermissions([CONTENT_SELECT_AND_SAVE_DELETE]) && !originalForm && (
                <>
                  <Button
                    reset
                    size="small"
                    className="saved-content__panel__delete-button"
                    onClick={() => handleToggleDeleteModal(!isDeleteModalOpen)}
                  >
                    Delete {contentType}
                  </Button>
                  <Modal open={isDeleteModalOpen}>
                    <ModalHeader
                      onClose={() => handleToggleDeleteModal(!isDeleteModalOpen)}
                      title="Delete Saved Content"
                      titleSub="Are you sure you want to delete this saved conversation?"
                    />
                    <ModalFooter>
                      <UtilityInlineGrid align="between">
                        <Button
                          type="link-muted"
                          className="u-p-l-0"
                          onClick={() => handleToggleDeleteModal(!isDeleteModalOpen)}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="danger"
                          onClick={() => handleDeleteSavedContent(activeSavedContentItem.id, isCopiedContent)}
                          className="button button--secondary"
                          loading={isSavedContentDeleteInProgress}
                        >
                          Yes, Delete {contentType}
                        </Button>
                      </UtilityInlineGrid>
                    </ModalFooter>
                  </Modal>
                </>
              )}
              <CopyToContentModalContainer
                isEHROptionVisible={OrganizationHelpers.canSendToEHR(currentOrg, activeUser)}
                isEHROptionChecked={sendContent}
                toggleEHROptionHandler={handleToggleSendContent}
                sendToEHRHandler={sendSavedContent}
                isCopyToContentModalOpen={isCopyToContentModalOpen}
                handleToggleCopyToModal={handleToggleCopyToModal}
                connectedParties={activeUserConnectedParties}
                activeUser={activeUser}
                savedContentId={activeSavedContentItem.id}
                activeSavedContentItem={activeSavedContentItem}
              />
            </div>
          </Scrollbars>
        </div>
      </div>
      <PdfViewerContainer
        open={isPdfViewerOpen}
        attachmentUrl={getAttachmentUrl(activeSavedContentItem.savedContentPdfLink, activeSavedContentItem.contact)}
        fileName={activeSavedContentItem.savedContentPdfLink}
        handlePdfViewerClose={() => togglePdfViewer(false)}
      />
    </>
  );
};

SavedContentPanel.propTypes = {
  allowMobileView: PropTypes.bool,
  handleMobileViewSavedContent: PropTypes.func,
};
export default SavedContentPanel;

import { createSlice } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import {
  getOrganization,
  postOrganization,
  getCallsByStatus,
  patchCall,
  updateCall,
  expireCall,
  getLastCallByUser,
  getCall,
  postCall,
  endCall,
  startCall,
} from '../services/RhinocallService';
import { generateUUID } from '../helpers/DataHelpers';
import { isChromeBrowser, isMobile } from '../helpers/BrowserHelpers';
import { formatPhoneIfValid } from '../helpers/PhoneHelpers';
import NotificationService from '../services/NotificationService';
import { getMemberCall, getActiveCall } from '../selectors/rhinocallSelector';
import {
  CALL_STATUS_INITIATED,
  CALL_STATUS_COMPLETED,
  CALL_CANCELLED,
}
  from '../constants/RhinocallConstants';
import { calls } from '../actions/NormalizrSchema';
import { deleteIdFromArray, addIdToArray } from '../helpers/ImmerHelpers';
import * as InboxActionTypes from '../constants/InboxActionTypes';
import * as UserActionTypes from '../constants/UserActionTypes';

const initialState = {
  display: 'preconfig',
  isOrgLoading: false,
  isDragging: false,
  isWebRTCInitiated: false,
  isAudioConnected: false,
  isRhinocallModalVisible: false,
  callId: null,
  isHangingUp: false,
  isCallConnecting: false,
  formInProgress: false,
  isAudioPublished: false,
};

const rhinocallSlice = createSlice({
  name: 'RHINOCALL',
  initialState: {
    ...initialState,
    org: {},
    error: null,
    androidPhoneNumber: null,
    lastMemberNumber: null,
    lastMemberNumberLoading: true,
    calls: {},
    activeCallIds: [],
    isHangingUp: false,
    isCompleteRequestInProgress: false,
    selectedAudioOutputDeviceId: null,
    audioInputDeviceId: null,
    audioDevices: [],
    rhinocallFromChannelId: null,
    rhinocallToChannelId: null,
  },
  reducers: {
    setRhinocallPreconfig: (state) => ({
      ...state,
      display: 'preconfig',
      isOrgLoading: false,
      isDragging: false,
      isWebRTCInitiated: false,
      isAudioConnected: false,
      isHangingUp: false,
      isCallConnecting: false,
      isRhinocallModalVisible: true,
      isAudioPublished: false,
    }),
    resetRhinocallState: (state) => ({
      ...state,
      ...initialState,
    }),
    receiveOrganization: (state, action) => ({
      ...state,
      org: {
        ...state.org,
        ...action.payload.org,
      },
      isOrgLoading: false,
    }),
    receiveCall: receiveCallData,
    receiveWebsocketCall: receiveCallData,
    receiveCompletedCall: receiveCompletedCallData,
    receiveActiveCall(state, action) {
      receiveCallData(state, action);
      state.callId = action.payload?._id;
    },
    receiveOrganizationError(state) {
      state.org = {};
      state.isOrgLoading = false;
    },
    requestOrganizationConfig(state) {
      state.isOrgLoading = true;
    },
    setDisplay(state, action) {
      state.display = action.payload;
    },
    setIsRhinocallModalVisible(state, action) {
      state.isRhinocallModalVisible = action.payload;
    },
    receiveCalls(state, action) {
      state.calls = action.payload.calls;
      state.activeCallIds = action.payload.activeCallIds;
      if (action.payload.callId) {
        state.callId = action.payload.callId;
      }
    },
    setIsDragging(state, action) {
      state.isDragging = action.payload;
    },
    setHangupInProgress(state, action) {
      state.isHangingUp = action.payload;
    },
    setAudioDevices(state, action) {
      state.audioDevices = action.payload;
    },
    setSelectedAudioOutputDeviceId(state, action) {
      state.selectedAudioOutputDeviceId = action.payload;
    },
    receiveAndroidPhoneNumber(state, action) {
      state.androidPhoneNumber = action.payload;
    },
    receiveLastNumberData(state, action) {
      if (action.payload) {
        state.lastMemberNumber = action.payload;
      }
      state.lastMemberNumberLoading = false;
    },
    receiveCreateCall(state, action) {
      addIdToArray(state.activeCallIds, action.payload._id);
      state.calls[action.payload._id] = action.payload;
      if (action.payload.optimisticId && state.calls[action.payload.optimisticId]) {
        delete state.calls[action.payload.optimisticId];
        deleteIdFromArray(state.activeCallIds, action.payload.optimisticId);
      }
      state.isCallConnecting = false;
      state.callId = action.payload._id;
      if (action.payload.memberNumber) {
        state.lastMemberNumber = action.payload.memberNumber;
      }
    },
    receiveCreateTempCall(state, action) {
      addIdToArray(state.activeCallIds, action.payload.optimisticId);
      state.callId = action.payload.optimisticId;
      state.calls[action.payload.optimisticId] = action.payload;
    },
    setCompleteRequestInProgress(state) {
      state.isCompleteRequestInProgress = true;
    },
    setRhinocallFromChannelId(state, action) {
      state.rhinocallFromChannelId = action.payload;
    },
    setRhinocallToChannelId(state, action) {
      state.rhinocallToChannelId = action.payload;
    },
    setIsWebRTCInitiated(state, action) {
      state.isWebRTCInitiated = action.payload;
    },
    setIsAudioConnected(state, action) {
      state.isAudioConnected = action.payload;
    },
    setDisplayComplete(state, action) {
      return {
        ...state,
        display: 'complete',
        isOrgLoading: false,
        isDragging: false,
        isWebRTCInitiated: false,
        isAudioConnected: false,
        isHangingUp: false,
        isCallConnecting: false,
        formInProgress: false,
        callId: action.payload,
        isAudioPublished: false,
      };
    },
    setActiveCallId(state, action) {
      state.callId = action.payload;
    },
    setFormInProgress(state, action) {
      state.formInProgress = action.payload;
    },
    setIsCallConnecting(state, action) {
      state.isCallConnecting = action.payload;
    },
    setIsAudioPublished(state, action) {
      state.isAudioPublished = action.payload;
    },
  },
  extraReducers: {
    [InboxActionTypes.setInboxContext]: resetUserOptions,
    [UserActionTypes.setActiveUser]: resetUserOptions,
  },
});

export default rhinocallSlice.reducer;

// ACTIONS

export const {
  resetRhinocallState,
  setIsRhinocallModalVisible,
  receiveOrganization,
  receiveOrganizationError,
  setDisplay,
  requestOrganizationConfig,
  receiveCalls,
  receiveCall,
  receiveError,
  setRhinocallPreconfig,
  removeCall,
  receiveLastNumberData,
  setIsDragging,
  setHangupInProgress,
  setAudioDevices,
  setSelectedAudioOutputDeviceId,
  receiveAndroidPhoneNumber,
  receiveCreateCall,
  receiveCreateTempCall,
  setCompleteRequestInProgress,
  setRhinocallFromChannelId,
  setRhinocallToChannelId,
  setIsWebRTCInitiated,
  receiveCompletedCall,
  setIsAudioConnected,
  setRhinocallUser,
  setDisplayComplete,
  setFormInProgress,
  receiveWebsocketCall,
  setIsCallConnecting,
  setActiveCallId,
  setIsAudioPublished,
  receiveActiveCall,
} = rhinocallSlice.actions;

function receiveCallData(state, action) {
  if (action.payload?._id) {
    state.calls[action.payload._id] = action.payload;
    if (action.payload.status !== CALL_STATUS_COMPLETED) {
      addIdToArray(state.activeCallIds, action.payload._id);
    }
    if (action.payload.optimisticId) {
      if (state.calls[action.payload.optimisticId]) {
        delete state.calls[action.payload.optimisticId];
      }
      deleteIdFromArray(state.activeCallIds, action.payload.optimisticId);
    }
  } else if (action.payload.optimisticId) {
    state.calls[action.payload.optimisticId] = action.payload;
  }
}

function receiveCompletedCallData(state, action) {
  if (action.payload._id && state.calls[action.payload._id]) {
    state.calls[action.payload._id] = action.payload;
    deleteIdFromArray(state.activeCallIds, action.payload._id);
  }
  if (action.payload.optimisticId && state.calls[action.payload.optimisticId]) {
    state.calls[action.payload.optimisticId] = action.payload;
    deleteIdFromArray(state.activeCallIds, action.payload.optimisticId);
  }
  state.isCompleteRequestInProgress = false;
}

function resetUserOptions(state) {
  if (!state.isRhinocallModalVisible) {
    return {
      ...state,
      selectedAudioOutputDeviceId: null,
      audioDevices: [],
      rhinocallFromChannelId: null,
      rhinocallToChannelId: null,
      display: 'preconfig',
    };
  } return {
    ...state,
  };
}

export function initiateCall(payload) {
  return async (dispatch) => {
    try {
      dispatch(setIsCallConnecting(true));
      if (payload.memberNumber) {
        const callLog = await postCall(payload);
        dispatch(updateCallOrCancel(callLog.data));
      } else {
        const callPayload = { ...payload, optimisticId: generateUUID() };
        dispatch(receiveCreateTempCall(shapeTempCallPayload(callPayload)));
        const { sessionId, callLog } = await startCall(callPayload);
        callPayload.sessionId = sessionId;
        await dispatch(updateCallOrCancel(callPayload));
        await dispatch(updateCallOrCancel(callLog.data));
      }
    } catch (err) {
      if (err.message !== CALL_CANCELLED) {
        NotificationService('createCall', err.response, err.message);
      }
    }
  };
}

function isCallCompleted(callPayload) {
  return (dispatch, getState) => {
    const { calls: existingCalls, isHangingUp } = getState().rhinocall;
    return existingCalls[callPayload.optimisticId]?.status === CALL_STATUS_COMPLETED || existingCalls[callPayload._id]?.status === CALL_STATUS_COMPLETED || isHangingUp;
  };
}

export function updateCallOrCancel(callPayload) {
  return async (dispatch) => {
    // Confirm call is still active
    if (!dispatch(isCallCompleted(callPayload))) {
      if (!callPayload._id) {
        return dispatch(setIsWebRTCInitiated(true));
      } else {
        return dispatch(receiveCreateCall(callPayload));
      }
    }
    // Otherwise, set to complete
    await dispatch(handleCompleteCall(callPayload));
    await endCall();
    throw new Error(CALL_CANCELLED);
  };
}

export function handleCompleteCall(call) {
  return async (dispatch) => {
    let completedCall = call;
    if (call?._id && call.status !== CALL_STATUS_COMPLETED) {
      const data = {
        status: CALL_STATUS_COMPLETED,
        disconnectCause: call?.disconnectCause,
      };
      try {
        dispatch(setCompleteRequestInProgress(true));
        const updatedCallRecord = await updateCall(call._id, data);
        completedCall = updatedCallRecord.data;
      } catch (err) {
        //
      }
    }
    dispatch(receiveCompletedCall(shapeCall(completedCall, CALL_STATUS_COMPLETED)));
  };
}

export function handleHangup() {
  return async (dispatch, getState) => {
    dispatch(setHangupInProgress(true));

    const currentState = getState();
    const { isWebRTCInitiated, isAudioPublished } = currentState.rhinocall;
    const currentCall = getActiveCall(currentState);

    // Disconnect from Bandwidth webrtc connection
    if (isWebRTCInitiated) {
      await endCall(isAudioPublished);
    }
    if (currentCall?._id) {
      return dispatch(setDisplayComplete(currentCall._id));
    }
    return dispatch(resetRhinocallState());
  };
}

export function fetchActiveCalls(userId) {
  return (dispatch) => getCallsByStatus('[$ne]=Completed')
    .then((response) => {
      const normalized = normalize(response.data, [calls]);
      const activeCallId = isMobile() ? response.data?.find((call) => call.userId === userId)?._id : null;
      dispatch(receiveCalls(getActiveCallsPayload(normalized, activeCallId)));
    })
    .catch((err) => {
      console.error(err);
    });
}

export function expireCalls() {
  return () => expireCall()
    .catch((err) => {
      console.error(err);
    });
}

export function fetchRhinoCallOrganizationConfiguration(orgId, userId = null) {
  return (dispatch) => {
    dispatch(requestOrganizationConfig());
    return getOrganization(orgId)
      .then((response) => {
        dispatch(receiveOrganization(shapeOrganizationPayloadFromCallService(response?.data)));
        if (response?.data?.isEnabled && userId) {
          dispatch(fetchActiveCalls(userId));
        }
      })
      .catch(() => {
        dispatch(receiveOrganizationError());
      });
  };
}

export function createOrganizationConfiguration(payload) {
  return organizationConfigurationRequest(payload, 'createCallOrganizationConfiguration');
}

export function updateOrganizationConfiguration(payload) {
  return organizationConfigurationRequest(payload, 'updateCallOrganizationConfiguration');
}

export function organizationConfigurationRequest(payload, notification) {
  return (dispatch) => {
    dispatch(requestOrganizationConfig());
    return postOrganization(payload)
      .then((response) => {
        dispatch(receiveOrganization(shapeOrganizationPayloadFromCallService(response?.data)));
        NotificationService(notification, response);
      })
      .catch((err) => {
        dispatch(receiveOrganizationError());
        NotificationService(notification, err.response);
      });
  };
}

export function updateCallConnectionResult(callLogId, connectionResult) {
  return async (dispatch) => {
    try {
      dispatch(setFormInProgress(true));
      const updatedCallRecord = await patchCall(callLogId, { connectionResult, status: CALL_STATUS_COMPLETED });
      dispatch(receiveCompletedCall(updatedCallRecord.data));
      dispatch(resetRhinocallState());
    } catch (err) {
      dispatch(setFormInProgress(false));
      NotificationService('updateCall', err.response);
    }
  };
}

export function receiveWebSocketEventMessage(websocketEvent) {
  return async (dispatch, getState) => {
    const currentState = getState();
    const isCurrentCall = websocketEvent.call?._id === currentState.rhinocall.callId || websocketEvent.call?.optimisticId === currentState.rhinocall.callId;
    const callPayload = websocketEvent.call;
    if (!dispatch(isCallCompleted(callPayload))) {
      if (websocketEvent.event === 'call:disconnected') {
        if (isCurrentCall) {
          if (!currentState.rhinocall.isHangingUp) {
            await dispatch(handleHangup());
          }
          if (websocketEvent.call.errorMessage) {
            NotificationService('createCall', null, websocketEvent.call.errorMessage);
          }
        } else {
          dispatch(receiveWebsocketCall(websocketEvent.call));
        }
      } else if (websocketEvent.event === 'call:connected') {
        dispatch(receiveWebsocketCall(websocketEvent.call));
      }
    }
  };
}

export function checkAudioPermission() {
  return async (dispatch) => {
    // Request audio permissions
    const stream = await navigator.mediaDevices?.getUserMedia({ audio: true });

    // If Chrome, get list of available devices
    if (isChromeBrowser()) {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter((device) => ['audioinput', 'audiooutput'].includes(device.kind));
      dispatch(setAudioDevices(audioDevices));
    }

    // Clean up local streams created in permissions request
    stream.getTracks().forEach((track) => {
      track.stop();
    });

    dispatch(setRhinocallPreconfig());
  };
}

export function getLastMemberNumber() {
  return async (dispatch) => {
    try {
      const response = await getLastCallByUser();
      const phoneNumber = formatPhoneIfValid(response?.data?.memberNumber);
      dispatch(receiveLastNumberData(phoneNumber));
    } catch {
      dispatch(receiveLastNumberData());
    }
  };
}

export function receiveAndroidPhone(androidPhone) {
  return (dispatch) => {
    const phoneNumber = formatPhoneIfValid(androidPhone);
    dispatch(receiveAndroidPhoneNumber(phoneNumber));
  };
}

export function checkActiveMemberCall() {
  return (dispatch, getState) => {
    const memberCall = getMemberCall(getState());
    if (memberCall?._id) {
      dispatch(getActiveMemberCall(memberCall._id));
    }
  };
}

export function getActiveMemberCall(callLogId) {
  return (dispatch) => getCall(callLogId).then((response) => {
    if (response.data.status === CALL_STATUS_COMPLETED && !response.data.connectionResult) {
      dispatch(setDisplayComplete(callLogId));
    }
    dispatch(receiveActiveCall(response.data));
  });
}

// SHAPERS
function shapeOrganizationPayloadFromCallService(org) {
  if (!org) {
    return {};
  }
  return {
    org: {
      id: org._id,
      isCallEnabled: !!org.isEnabled,
      channelLimit: org.channelLimit,
      isRhinophoneEnabled: !!org.isRhinophoneEnabled,
    },
  };
}

function shapeCall(call, status) {
  return {
    ...call,
    ...status && {
      status,
    },
  };
}

function shapeTempCallPayload(payload) {
  return {
    optimisticId: payload.optimisticId,
    status: CALL_STATUS_INITIATED,
    from: payload.fromNumber,
    to: payload.toNumber,
    contactId: payload.contactId,
    organizationId: payload.organizationId,
    userId: payload.userId,
    ...payload.deviceId && {
      deviceId: payload.deviceId,
    },
  };
}

function getActiveCallsPayload(normalizedCalls, callId) {
  return {
    calls: normalizedCalls.entities.calls || {},
    activeCallIds: normalizedCalls.result || [],
    callId,
  };
}

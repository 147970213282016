import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Resource,
  ResourceIntro,
  ResourceGroup,
  UtilitySystem,
  Scrollbars,
  Input,
  Icon,
  LoaderPulse,
  Dropdown,
  DropdownMenuItem,
  Select,
} from 'rhinostyle';
import * as AppConstants from '../constants/AppConstants';
import { UserHelpers, SearchHelpers } from '../helpers';
import * as DataHelpers from '../helpers/DataHelpers';
import UserSearchResult from './UserSearchResult';
import { isSpecifiedIntegrationType } from '../helpers/OrganizationHelpers';
import { TYPE_INTEGRATION_EPIC, TYPE_USER_LIBRARY_SAVED_CONTENT } from '../constants/Types';
import UserSearchWithConnectedParties from './UserSearchWithConnectedParties';
import { fullTimestamp, formatTimestamp } from '../helpers/DateHelpers';

const CopyToContent = (props) => {
  const {
    isCopyToContentModalOpen,
    handleSave,
    closeModal,
    connectedParties,
    types,
    selectContactHandler,
    activeUser,
    isEHROptionVisible,
    isEHROptionChecked,
    toggleEHROptionHandler,
    contactSearchValue,
    searchHandler,
    userSearchIds,
    users,
    selectedContactId,
    activeSavedContentItem,
    isCopyNowButtonLoading,
    usersLoading,
    activeFilterParam,
    handleFilterChange,
    inputFormat,
    copyToContentContactListRef,
    onScroll,
    currentOrganization,
    handleOfficeChange,
    officesList,
    savedContentOfficeId,
    handleContactSelect,
    copyToContentErrors,
  } = props;

  const isEpicIntegration = isSpecifiedIntegrationType(currentOrganization, TYPE_INTEGRATION_EPIC);
  const showCopyEHROption = isEpicIntegration ? activeSavedContentItem?.libraryTypeId === TYPE_USER_LIBRARY_SAVED_CONTENT : true;
  const renderConnectedParties = () => {
    if (!connectedParties || connectedParties.length === 0) {
      return null;
    }
    const distinctUsers = DataHelpers.distinctObjectsFromArray(connectedParties, 'userId');

    if (distinctUsers.length === 0) {
      return null;
    }

    return (
      <Scrollbars className="resource-group__scroll" autoHeight autoHeightMax={UtilitySystem.config.resourceSizes.small}>
        <ResourceGroup interfaceMode="radio">
          {distinctUsers.map((connectedParty) => {
            const profileImageUrl = connectedParty.userProfileImageUrl ? `${AppConstants.AVATAR_BASE_URL}${connectedParty.userProfileImageUrl}` : '';
            const relation = types[connectedParty.connectionTypeId].value;
            const { userFirstName, userLastName } = connectedParty;
            return (
              <Resource
                id={connectedParty.userId}
                selected={selectedContactId === connectedParty.userId}
                key={connectedParty.userId}
                onClick={() => selectContactHandler(userFirstName, userLastName, connectedParty.userId)}
              >
                <ResourceIntro
                  avatar={{
                    image: profileImageUrl,
                    name: UserHelpers.formatAvatarName(userFirstName, userLastName),
                    type: 'member',
                    showOnlineStatus: false,
                  }}
                  title={`${userFirstName} ${userLastName}`}
                >
                  {relation}
                </ResourceIntro>
              </Resource>
            );
          })}
        </ResourceGroup>
      </Scrollbars>
    );
  };

  const renderSearchedContactList = () => {
    if (userSearchIds.length === 0) {
      return (
        <span>No Records Found</span>
      );
    }

    return (
      <Scrollbars
        className="resource-group__scroll"
        onScroll={onScroll}
        ref={copyToContentContactListRef}
        autoHeight
        autoHeightMax={UtilitySystem.config.resourceSizes.small}
      >
        <ResourceGroup interfaceMode="radio">
          {userSearchIds.map((userId) => {
            const handleClick = (id) => {
              const user = users[id];
              const { firstName, lastName } = user;
              selectContactHandler(firstName, lastName, user.id);
            };

            return (
              <UserSearchResult
                userId={userId}
                key={userId}
                handleClick={handleClick}
                isSelected={selectedContactId === userId}
                scope="nonMembers"
                activeFilterParam={activeFilterParam}
                searchText={contactSearchValue}
                interfaceMode="radio"
                isRightResourceDetailsVisible={false}
              />
            );
          })}
        </ResourceGroup>
      </Scrollbars>
    );
  };

  const renderFilterOption = (id) => (
    <DropdownMenuItem key={id} id={id} label={SearchHelpers.getFilterLabelInformation(id).label} />
  );

  return (
    <div>
      <Modal open={isCopyToContentModalOpen}>
        <ModalHeader onClose={closeModal} title="Copy PDF in Rhinogram" titleSub="To Rhinogram Library" />
        <ModalBody>
          {isCopyToContentModalOpen && (
            <div className="copy-to-modal">
              <div className="">
                <h5>Patient / Connected Parties</h5>
                <Input
                  placeholder={activeFilterParam !== 'name' ? SearchHelpers.getFilterLabelInformation(activeFilterParam).placeholder : 'Search Contact'}
                  className="search__input"
                  onChange={searchHandler}
                  initialValue={contactSearchValue}
                  addon="both"
                  size="large"
                  format={inputFormat}
                  name="nonMembers"
                  autoFocus
                  autoComplete="off"
                >
                  <Icon icon="search" />
                  <Dropdown
                    size="small"
                    hideCaret
                    wide
                    activeKey={activeFilterParam}
                    type="input"
                    onSelect={handleFilterChange}
                    title="Search by"
                    icon="filter"
                    className="u-text-muted u-text-large"
                    position="right"
                  >
                    {SearchHelpers.filterContactParams.filterParams.map(renderFilterOption)}
                  </Dropdown>
                </Input>
                {usersLoading ? (
                  <div className="u-text-center">
                    <LoaderPulse type="secondary" />
                  </div>
                ) : (
                  <div className="search-result-wrapper">
                    {contactSearchValue.length < 3 ? renderConnectedParties() : renderSearchedContactList()}
                  </div>
                )}
              </div>
              <br />
              {(isEHROptionVisible && showCopyEHROption) && (
                <div className="u-m-b-large text-content-center">
                  <h3>Copy PDF to EHR</h3>
                  <Checkbox
                    name="sendContentCheckbox"
                    isChecked={isEHROptionChecked}
                    onChange={() => toggleEHROptionHandler(!isEHROptionChecked)}
                    label={<span>Copy to <b>{activeUser.firstName} {activeUser.lastName}&apos;s</b> or selected patient&apos;s record as a Clinical Document</span>}
                    className="text-content-left"
                  />
                  {activeSavedContentItem?.lastEHRActivity?.sender && (
                    <span className=" u-text-muted">
                      copied on {formatTimestamp(activeSavedContentItem.lastEHRActivity.timestamp, 'ddd, MMM D, Y, h:mm a', true)} by&nbsp;
                      {activeSavedContentItem.lastEHRActivity.sender.firstName} {activeSavedContentItem.lastEHRActivity.sender.lastName}
                    </span>
                  )}
                </div>
              )}
              {(isEpicIntegration && showCopyEHROption) && (
                <div className="row form__group">
                  <div className="column-6@medium">
                    <b>Encounter Date:</b>
                    <p>{fullTimestamp(activeSavedContentItem?.encounterDate, 'ddd, MMM D, Y, h:mm a')}</p>
                  </div>
                  <div className="column-6@medium">
                    <Select
                      onSelect={handleOfficeChange}
                      placeholder="Select"
                      label="Office ID"
                      name="savedContentOfficeId"
                      options={officesList}
                      disabled={!isEHROptionChecked}
                      selected={savedContentOfficeId}
                      required
                      validationMessage={copyToContentErrors.savedContentOfficeId}
                    />
                  </div>
                  <div>
                    <UserSearchWithConnectedParties
                      activeUser={activeUser}
                      handleContactSelect={handleContactSelect}
                      disabled={!isEHROptionChecked}
                      contactErrors={copyToContentErrors}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="u-text-right">
            <Button
              disabled={!((isEHROptionVisible && isEHROptionChecked) || selectedContactId)}
              onClick={handleSave}
              type="secondary"
              data-cypress="Copy Now"
              loading={isCopyNowButtonLoading}
            >
              Copy Now
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

CopyToContent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isCopyToContentModalOpen: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  connectedParties: PropTypes.array.isRequired,
  types: PropTypes.object.isRequired,
  selectedContactId: PropTypes.any,
  selectContactHandler: PropTypes.func.isRequired,
  activeUser: PropTypes.object.isRequired,
  isEHROptionVisible: PropTypes.bool.isRequired,
  isEHROptionChecked: PropTypes.bool.isRequired,
  toggleEHROptionHandler: PropTypes.func.isRequired,
  searchHandler: PropTypes.func.isRequired,
  contactSearchValue: PropTypes.string.isRequired,
  activeFilterParam: PropTypes.string.isRequired,
  userSearchIds: PropTypes.array,
  users: PropTypes.object,
  activeSavedContentItem: PropTypes.object.isRequired,
  isCopyNowButtonLoading: PropTypes.bool.isRequired,
  usersLoading: PropTypes.bool.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  inputFormat: PropTypes.any,
  copyToContentContactListRef: PropTypes.func.isRequired,
  onScroll: PropTypes.func,
  currentOrganization: PropTypes.object,
  handleOfficeChange: PropTypes.func,
  officesList: PropTypes.array,
  savedContentOfficeId: PropTypes.number,
  handleContactSelect: PropTypes.func,
  copyToContentErrors: PropTypes.object,
};

export default CopyToContent;
